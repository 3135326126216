import styled from "styled-components";
const getColorVariation = alternative => (alternative ? "#1b1b20" : "#161618 ");

const Section = styled.section`
  width: 100%;
  background-color: ${p => getColorVariation(p.alternative)};

  > * {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  &:before {
    left: 0;
    width: 100%;
    height: 50px;
    background-size: 50px 100%;
    position: absolute;
    content: "";
    pointer-events: none;
    z-index: 10;
  }

  &:before {
    background-image: linear-gradient(
        135deg,
        ${p => getColorVariation(!p.alternative)} 25%,
        transparent 25%
      ),
      linear-gradient(
        225deg,
        ${p => getColorVariation(!p.alternative)} 25%,
        transparent 25%
      );
  }
  /* &:after {
  transform: rotate(180deg);
  margin-top: -50px;
} */
`;

export default Section;
