import React from "react";
import PropTypes from "prop-types";
import Footer from "../components/Footer/Footer";
import * as S from "./layout.style";

const Layout = ({ children }) => {
  return (
    <S.Container>
      <S.Global />
      <S.Main>{children}</S.Main>
      <Footer />
    </S.Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
