import styled from "styled-components";

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  span {
    text-align: center;
  }
`;
