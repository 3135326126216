import styled, { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
    html, body {
      margin: 0;
      font-family: 'Open Sans', sans-serif;
      color: white;
      background-color: #161618;
      height: 100%;
      width: 100%;
      #___gatsby {
        width: 100%;
        height: 100%;
        > div {
          width: 100%;
          height: 100%;
        }
      }
    }
    h1, h2, h3, h4, h5, h6 {
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    }
    h1 {
      font-size: 5rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    a {
      color: white;
    }
`;

export const Container = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; */
`;

export const Main = styled.main`
  width: 100%;
  /* display: flex;
  justify-content: center; */
`;
