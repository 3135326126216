import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Section from "../Section/Section.style";
import * as S from "./Footer.style";

const Footer = () => (
  <Section alternative>
    <Grid>
      <Row>
        <Col xs={12}>
          <S.Footer>
            <span>Fluubo Oy, 3101039-1</span>
            <span>ville.halminen@fluubo.com</span>
            <span>Helsinki, Finland</span>
          </S.Footer>
        </Col>
      </Row>
    </Grid>
  </Section>
);

export default Footer;
